<template>
  <section id="listaSped">
    <div class="cabecalho d-flex justify-content-between">
      <Breadcrumb></Breadcrumb>

      <div class="cabecalho-inputs d-flex row">
        <div
          id="filter-input-container"
          class="cabecalho-tools d-flex justify-content-between"
        >
          <input
            class="cabecalho-input"
            id="filter-input"
            type="text"
            placeholder="Código de Oportunidade, Empresa, CNPJ..."
            v-model="searchQuery"
          />
          <button
            :disabled="loadingFilter"
            class="btn"
            type="submit"
            variant="dark"
          >
            <Icon :iconName="'search'" class="nav-icon" />
          </button>
        </div>

        <button class="cabecalho-tools cabecalho-btn" @click="btnModalTeses()">
          Enviar Arquivos
          <Icon :iconName="'upload'" class="nav-icon" />
        </button>
      </div>
    </div>

    <div class="jumbotron">
      <b-overlay
        :show="loadingSpinner"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="secondary"
      >
        <div class="p-1">
          <table class="table">
            <thead>
              <tr>
                <th style="cursor: pointer" scope="col" @click="sort('nCodOp')">
                  Código da Oportunidade
                </th>
                <th style="cursor: pointer" scope="col" @click="sort('nome')">
                  Empresa
                </th>
                <th style="cursor: pointer" scope="col">CNPJ</th>
                <th
                  style="cursor: pointer"
                  scope="col"
                  @click="sort('dataEnvio')"
                >
                  Data de Envio
                </th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sped, index) in filteredListaSpeds" :key="index">
                <td scope="row">#{{ sped.nCodOp }}</td>
                <td>{{ sped.nome }}</td>
                <td>{{ formatCnpj(sped.cnpj) }}</td>
                <td>{{ formatDatePTBR(sped.dataEnvio) }}</td>
                <td>
                  <button
                    class="btn btn-outline-green"
                    @click.prevent="fetchCalculoRestituicao(sped.nCodOp)"
                  >
                    Visualizar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>

      <div class="pagination-nav justify-content-center row my-1">
        <button @click="pagNavigation('first')">
          <Icon :iconName="'chevron-double-left'" class="nav-icon" />
        </button>
        <button @click="pagNavigation('previous')">
          <Icon :iconName="'chevron-left'" class="nav-icon" />
        </button>
        <button id="pag-current-page">{{ currentPage }}</button>
        <button @click="pagNavigation('next')">
          <Icon :iconName="'chevron-right'" class="nav-icon" />
        </button>
        <button @click="pagNavigation('last')">
          <Icon :iconName="'chevron-double-right'" class="nav-icon" />
        </button>
      </div>
    </div>
  </section>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.11/vue.js"></script>
<script src="https://unpkg.com/bootstrap-vue@2.6.1/dist/bootstrap-vue.min.js"></script>
<!-- <link
  href="https://unpkg.com/bootstrap-vue@2.6.1/dist/bootstrap-vue.css"
  rel="stylesheet"
/>
<link
  href="https://unpkg.com/bootstrap@4.4.1/dist/css/bootstrap.min.css"
  rel="stylesheet"
/> -->

<script>
const API_URL = process.env.VUE_APP_URL_API;
import Breadcrumb from "@/Components/Breadcrumb/Breadcrumb.vue";
import Icon from "@/Components/Icon/icon.vue";
import restituicaoService from "@/services/teses/restituicao.service";
import { router } from "@/router";
import HelperService from "@/services/helper.service.js";

export default {
  name: "ListaSped",
  components: {
    Breadcrumb,
    Icon,
  },
  data() {
    return {
      loadingSpinner: false,
      listaSpeds: [],
      currentSort: "dataImportacao",
      currentSortDir: "desc",
      small: true,
      searchQuery: "",
      currentPage: 1,
      pageSize: 15,
      returnTeses: false,
    };
  },

  mounted() {
    this.getAllSpedSent();
  },

  methods: {
    // Returns a list of companies that already sent its sped file
    // Have the following rows: NCodOp, Nome, CNPJ, Data de Registro
    async getAllSpedSent() {
      (this.loadingSpinner = true),
        await restituicaoService
          .getAllSpedSent()
          .then((response) => {
            if (response.status == 200) {
              this.listaSpeds = response.data.dados;
            }
          })
          .catch((error) => {
            // return HelperService.callMakeToast(error, "error");
            this.$toast.open({ message: error, type: "error", duration: 2000 });
          });
      this.loadingSpinner = false;
    },

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    fetchCalculoRestituicao(nCodOp) {
      this.$router.push(`/calculo/restituicao/${nCodOp}/`);
    },

    formatDatePTBR(date) {
      const formatDate = date.split("T")[0].split("-").reverse().join("/");
      return formatDate;
    },

    formatCnpj(cnpj) {
      const formatedCnpj = cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
      return formatedCnpj;
    },

    pagNavigation(page) {
      switch (page) {
        case "first":
          this.currentPage = 1;
          break;
        case "previous":
          if (this.currentPage > 1) this.currentPage--;
          break;
        case "next":
          if (this.currentPage * this.pageSize < this.listaSpeds.length)
            this.currentPage++;
          break;
        case "last":
          this.currentPage = Math.ceil(this.listaSpeds.length / this.pageSize);
          break;
        default:
          try {
            if (page <= this.listaSpeds.length / this.pageSize && page >= 1)
              this.currentPage = page;
          } catch (error) {
            console.log("Cannot go to the page " + page);
          }
          break;
      }
    },

    teses(params) {
      this.returnTeses = params;
    },

    btnModalTeses() {
      this.$store.dispatch("updateshowAndHideModalTeses", true);
    },
  },

  computed: {
    sortedListaSpeds: function () {
      return this.listaSpeds.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },

    filteredListaSpeds: function () {
      let listaFiltrada = this.sortedListaSpeds.filter((speds) => {
        for (var sped in speds) {
          if (
            String(speds[sped]).indexOf(this.searchQuery.toUpperCase()) !== -1
          ) {
            return true;
          }
        }
        return false;
      });

      this.totalPages = Math.ceil(listaFiltrada.length / this.pageSize);

      return listaFiltrada.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });
    },
  },
};
</script>
